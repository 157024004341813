<template>
    <v-form ref="personal_info">
        <form-section :sectionTitle="name" wideFirstCol>
            <template v-slot:col-1>
                <form-element-group threecols>
                    <template v-slot:col-1>
                        <form-text-field
                            ref="first_name"
                            :value="profileData.first_name"
                            name="first_name"
                            required
                            :rules="[
                                (v) =>
                                    !!v.trim() ||
                                    $t('applications.first-name-required'),
                            ]"
                            icon="person"
                            :placeholder="$t('applications.enter-first-name')"
                            :label="$t('applications.first-name')"
                            @change="updateProfile('first_name', $event)"
                        ></form-text-field>
                    </template>
                    <template v-slot:col-2>
                        <form-text-field
                            ref="middle_name"
                            :label="$t('applications.middle-name')"
                            name="middle_name"
                            :value="
                                profileData.middle_name === null
                                    ? ''
                                    : profileData.middle_name
                            "
                            icon="person"
                            :placeholder="$t('applications.enter-middle-name')"
                            @change="updateProfile('middle_name', $event)"
                        ></form-text-field>
                    </template>
                    <template v-slot:col-3>
                        <form-text-field
                            ref="last_name"
                            :label="$t('applications.last-name')"
                            name="last_name"
                            required
                            :value="profileData.last_name"
                            :rules="[
                                (v) =>
                                    !!v.trim() ||
                                    $t('applications.last-name-required'),
                            ]"
                            icon="person"
                            :placeholder="$t('applications.enter-last-name')"
                            @change="updateProfile('last_name', $event)"
                        ></form-text-field>
                    </template>
                </form-element-group>
                <!-- Social Security  -->
                <form-element-group>
                    <template v-slot:col-1>
                        <zoom-x-transition group :duration="900">
                            <form-text-field
                                ref="ssn"
                                :label="$t('applications.social-security')"
                                v-if="formOfId === 'social-security'"
                                key="1"
                                :value="profileData.ssn"
                                icon="info"
                                :placeholder="$t('applications.enter-ssn')"
                                mask="###-##-####"
                                name="ssn"
                                :rules="[
                                    (v) =>
                                        (!!v && hasSSN) ||
                                        $t('applications.ssn-required'),
                                    (v) =>
                                        (/^\d{3}-\d{2}-\d{4}$/.test(v) &&
                                            hasSSN) ||
                                        $t('applications.ssn-invalid'),
                                ]"
                                :disabled="!hasSSN"
                                :required="hasSSN"
                                @change="updateProfile('ssn', $event)"
                            ></form-text-field>
                            <form-text-field
                                :label="$t('applications.itin')"
                                required
                                v-if="formOfId === 'itin'"
                                key="2"
                                :value="profileData.itin"
                                icon="info"
                                :placeholder="$t('applications.enter-itin')"
                                mask="social"
                                name="itin"
                                :rules="[
                                    (v) =>
                                        (!!v && hasITIN) ||
                                        $t('applications.itin-required'),
                                    (v) =>
                                        (/^\d{3}-\d{2}-\d{4}$/.test(v) &&
                                            hasITIN) ||
                                        $t('applications.itin-invalid'),
                                ]"
                                return-masked-value
                                :disabled="!hasITIN"
                                @change="updateProfile('itin', $event)"
                            ></form-text-field>

                            <div
                                style="width: 320px; height: 60px"
                                v-if="formOfId === 'no-identification-number'"
                                key="3"
                                class="pt-5 pl-4"
                            >
                                <h3>
                                    {{
                                        $t(
                                            "applications.no-identification-number"
                                        )
                                    }}
                                </h3>
                            </div>
                        </zoom-x-transition>
                    </template>
                    <template v-slot:col-2>
                        <form-element :label="$t('applications.form-of-id')">
                            <v-select
                                class="mt-2"
                                solo
                                :items="items"
                                item-text="label"
                                item-value="slug"
                                v-model="formOfId"
                            ></v-select>
                        </form-element>
                    </template>
                </form-element-group>
                <!-- Birthdate ITIN -->
                <form-element-group>
                    <template v-slot:col-1>
                        <form-text-field
                            ref="birthdate"
                            :label="$t('applications.date-of-birth')"
                            required
                            placeholder="mm/dd/yyyy"
                            mask="##/##/####"
                            icon="calendar_today"
                            name="birthdate"
                            :value="profileData.birthdate"
                            :rules="[
                                (v) =>
                                    !!v ||
                                    $t('applications.date-of-birth-required'),
                                (v) =>
                                    /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                        v
                                    ) ||
                                    $t('applications.date-of-birth-invalid'),
                            ]"
                            @change="updateProfile('birthdate', $event)"
                        ></form-text-field>
                    </template>
                    <template v-slot:col-2>
                        <form-text-field
                            ref="freeze_code"
                            :label="$t('applications.freeze-code')"
                            name="freeze_code"
                            :value="profileData.freeze_code"
                            :placeholder="$t('applications.enter-freeze-code')"
                            :hint="$t('applications.freeze-code-hint')"
                            icon="lock"
                            @change="updateProfile('freeze_code', $event)"
                        ></form-text-field>
                    </template>
                </form-element-group>
                <!-- Phone / Email -->
                <form-element-group>
                    <template v-slot:col-1>
                        <form-text-field
                            ref="phone"
                            name="phone"
                            :value="profileData.mobile_phone"
                            :label="$t('applications.phone')"
                            required
                            icon="phone_iphone"
                            :rules="[
                                (v) =>
                                    !!v ||
                                    $t('applications.phone-number-required'),
                                (v) =>
                                    v.length == 14 ||
                                    $t('applications.phone-number-invalid'),
                            ]"
                            mask="(###)-###-####"
                            masked
                            :placeholder="$t('applications.enter-phone')"
                            @change="updateProfile('mobile_phone', $event)"
                        ></form-text-field>
                    </template>
                    <template v-slot:col-2>
                        <form-text-field
                            :label="$t('applications.email')"
                            :placeholder="$t('applications.enter-email')"
                            icon="mail_outline"
                            name="email"
                            required
                            :value="profileData.email"
                            :rules="[
                                (v) => !!v || $t('applications.email-required'),
                                (v) =>
                                    /^[\w]+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                        v
                                    ) || $t('applications.email-invalid'),
                            ]"
                            @change="updateProfile('email', $event)"
                        ></form-text-field>
                    </template>
                </form-element-group>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import { ZoomXTransition } from "vue2-transitions";
export default {
    name: "personal-info",
    components: {
        ZoomXTransition,
    },
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasSSN() {
            return this.formOfId === "social-security";
        },
        hasITIN() {
            return this.formOfId === "itin";
        },
    },
    data() {
        return {
            profileData: Object.assign({}, this.profile),
            valid: {},
            formOfId: "social-security",
            items: [
                {
                    slug: "social-security",
                    label: this.$t("applications.social-security"),
                },
                {
                    slug: "itin",
                    label: this.$t("applications.itin"),
                },
                {
                    slug: "no-identification-number",
                    label: this.$t("applications.no-identification-number"),
                },
            ],
            name: this.$t("applications.personal-information"),
        };
    },
    methods: {
        validate() {
            return this.$refs["personal_info"].validate();
        },
        updateProfile(key, data) {
            this.profileData[key] = data;
            // calculate name on update
            this.profileData.name = `${this.profileData.first_name} ${this.profileData.last_name}`;

            this.$emit("update", this.profileData);
        },
    },
};
</script>

<style scoped>
</style>