var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "personal_info" },
    [
      _c("form-section", {
        attrs: { sectionTitle: _vm.name, wideFirstCol: "" },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-element-group", {
                  attrs: { threecols: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "col-1",
                      fn: function() {
                        return [
                          _c("form-text-field", {
                            ref: "first_name",
                            attrs: {
                              value: _vm.profileData.first_name,
                              name: "first_name",
                              required: "",
                              rules: [
                                function(v) {
                                  return (
                                    !!v.trim() ||
                                    _vm.$t("applications.first-name-required")
                                  )
                                }
                              ],
                              icon: "person",
                              placeholder: _vm.$t(
                                "applications.enter-first-name"
                              ),
                              label: _vm.$t("applications.first-name")
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateProfile("first_name", $event)
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "col-2",
                      fn: function() {
                        return [
                          _c("form-text-field", {
                            ref: "middle_name",
                            attrs: {
                              label: _vm.$t("applications.middle-name"),
                              name: "middle_name",
                              value:
                                _vm.profileData.middle_name === null
                                  ? ""
                                  : _vm.profileData.middle_name,
                              icon: "person",
                              placeholder: _vm.$t(
                                "applications.enter-middle-name"
                              )
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateProfile("middle_name", $event)
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "col-3",
                      fn: function() {
                        return [
                          _c("form-text-field", {
                            ref: "last_name",
                            attrs: {
                              label: _vm.$t("applications.last-name"),
                              name: "last_name",
                              required: "",
                              value: _vm.profileData.last_name,
                              rules: [
                                function(v) {
                                  return (
                                    !!v.trim() ||
                                    _vm.$t("applications.last-name-required")
                                  )
                                }
                              ],
                              icon: "person",
                              placeholder: _vm.$t(
                                "applications.enter-last-name"
                              )
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateProfile("last_name", $event)
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("form-element-group", {
                  scopedSlots: _vm._u([
                    {
                      key: "col-1",
                      fn: function() {
                        return [
                          _c(
                            "zoom-x-transition",
                            { attrs: { group: "", duration: 900 } },
                            [
                              _vm.formOfId === "social-security"
                                ? _c("form-text-field", {
                                    key: "1",
                                    ref: "ssn",
                                    attrs: {
                                      label: _vm.$t(
                                        "applications.social-security"
                                      ),
                                      value: _vm.profileData.ssn,
                                      icon: "info",
                                      placeholder: _vm.$t(
                                        "applications.enter-ssn"
                                      ),
                                      mask: "###-##-####",
                                      name: "ssn",
                                      rules: [
                                        function(v) {
                                          return (
                                            (!!v && _vm.hasSSN) ||
                                            _vm.$t("applications.ssn-required")
                                          )
                                        },
                                        function(v) {
                                          return (
                                            (/^\d{3}-\d{2}-\d{4}$/.test(v) &&
                                              _vm.hasSSN) ||
                                            _vm.$t("applications.ssn-invalid")
                                          )
                                        }
                                      ],
                                      disabled: !_vm.hasSSN,
                                      required: _vm.hasSSN
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProfile("ssn", $event)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.formOfId === "itin"
                                ? _c("form-text-field", {
                                    key: "2",
                                    attrs: {
                                      label: _vm.$t("applications.itin"),
                                      required: "",
                                      value: _vm.profileData.itin,
                                      icon: "info",
                                      placeholder: _vm.$t(
                                        "applications.enter-itin"
                                      ),
                                      mask: "social",
                                      name: "itin",
                                      rules: [
                                        function(v) {
                                          return (
                                            (!!v && _vm.hasITIN) ||
                                            _vm.$t("applications.itin-required")
                                          )
                                        },
                                        function(v) {
                                          return (
                                            (/^\d{3}-\d{2}-\d{4}$/.test(v) &&
                                              _vm.hasITIN) ||
                                            _vm.$t("applications.itin-invalid")
                                          )
                                        }
                                      ],
                                      "return-masked-value": "",
                                      disabled: !_vm.hasITIN
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateProfile("itin", $event)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.formOfId === "no-identification-number"
                                ? _c(
                                    "div",
                                    {
                                      key: "3",
                                      staticClass: "pt-5 pl-4",
                                      staticStyle: {
                                        width: "320px",
                                        height: "60px"
                                      }
                                    },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "applications.no-identification-number"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "col-2",
                      fn: function() {
                        return [
                          _c(
                            "form-element",
                            {
                              attrs: {
                                label: _vm.$t("applications.form-of-id")
                              }
                            },
                            [
                              _c("v-select", {
                                staticClass: "mt-2",
                                attrs: {
                                  solo: "",
                                  items: _vm.items,
                                  "item-text": "label",
                                  "item-value": "slug"
                                },
                                model: {
                                  value: _vm.formOfId,
                                  callback: function($$v) {
                                    _vm.formOfId = $$v
                                  },
                                  expression: "formOfId"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("form-element-group", {
                  scopedSlots: _vm._u([
                    {
                      key: "col-1",
                      fn: function() {
                        return [
                          _c("form-text-field", {
                            ref: "birthdate",
                            attrs: {
                              label: _vm.$t("applications.date-of-birth"),
                              required: "",
                              placeholder: "mm/dd/yyyy",
                              mask: "##/##/####",
                              icon: "calendar_today",
                              name: "birthdate",
                              value: _vm.profileData.birthdate,
                              rules: [
                                function(v) {
                                  return (
                                    !!v ||
                                    _vm.$t(
                                      "applications.date-of-birth-required"
                                    )
                                  )
                                },
                                function(v) {
                                  return (
                                    /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                      v
                                    ) ||
                                    _vm.$t("applications.date-of-birth-invalid")
                                  )
                                }
                              ]
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateProfile("birthdate", $event)
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "col-2",
                      fn: function() {
                        return [
                          _c("form-text-field", {
                            ref: "freeze_code",
                            attrs: {
                              label: _vm.$t("applications.freeze-code"),
                              name: "freeze_code",
                              value: _vm.profileData.freeze_code,
                              placeholder: _vm.$t(
                                "applications.enter-freeze-code"
                              ),
                              hint: _vm.$t("applications.freeze-code-hint"),
                              icon: "lock"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateProfile("freeze_code", $event)
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("form-element-group", {
                  scopedSlots: _vm._u([
                    {
                      key: "col-1",
                      fn: function() {
                        return [
                          _c("form-text-field", {
                            ref: "phone",
                            attrs: {
                              name: "phone",
                              value: _vm.profileData.mobile_phone,
                              label: _vm.$t("applications.phone"),
                              required: "",
                              icon: "phone_iphone",
                              rules: [
                                function(v) {
                                  return (
                                    !!v ||
                                    _vm.$t("applications.phone-number-required")
                                  )
                                },
                                function(v) {
                                  return (
                                    v.length == 14 ||
                                    _vm.$t("applications.phone-number-invalid")
                                  )
                                }
                              ],
                              mask: "(###)-###-####",
                              masked: "",
                              placeholder: _vm.$t("applications.enter-phone")
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateProfile("mobile_phone", $event)
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "col-2",
                      fn: function() {
                        return [
                          _c("form-text-field", {
                            attrs: {
                              label: _vm.$t("applications.email"),
                              placeholder: _vm.$t("applications.enter-email"),
                              icon: "mail_outline",
                              name: "email",
                              required: "",
                              value: _vm.profileData.email,
                              rules: [
                                function(v) {
                                  return (
                                    !!v || _vm.$t("applications.email-required")
                                  )
                                },
                                function(v) {
                                  return (
                                    /^[\w]+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                      v
                                    ) || _vm.$t("applications.email-invalid")
                                  )
                                }
                              ]
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateProfile("email", $event)
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }